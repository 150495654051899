import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const COVID19Page = () => (
  <Layout>
    <SEO title="Home" />
    <section className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
      <h1 className="mt-1 text-4xl tracking-tight leading-10 font-medium text-gray-900 sm:leading-none sm:text-3xl lg:text-4xl xl:text-5xl">
        COVID-19 Update
      </h1>
      <p className="mt-3 text-base text-gray-500 sm:mt-5">
        Currently we have been advised by the state chiropractic association
        that we should remain open in order to help reduce the current burden on
        medical offices and urgent care clinics. However, this may change at any
        time so please call in advance if you are planning on coming into the
        office.
      </p>
      <p className="mt-3 text-base text-gray-500 sm:mt-5">
        We are also taking extra precautions with cleaning tables and sanitizing
        hands between every patient. If you are in any of the high risk groups
        we still recommend that you stay home. And if you have any flu like
        symptoms, a cough, fever, etc please DO NOT come into the office.{" "}
      </p>
      <p className="mt-3 text-base text-gray-500 sm:mt-5">
        Thank you, <br />
        Dr. Ian Davis
      </p>
    </section>
  </Layout>
)

export default COVID19Page
